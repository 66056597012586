











import Component from 'vue-class-component';

import UiPageHeader from '@/components/ui/UiPageHeader.vue';

import { BaseVue } from '../BaseVue';
import Security from '../components/org/Security.vue';

@Component({
  components: {
    Security,
    UiPageHeader,
  },
})
export default class SSOClass extends BaseVue {
  public selectedTab = 0;
}
