<template>
  <v-layout class="tw-border tw-border-neutral-100 tw-rounded-lg tw-overflow-hidden" color="white">
    <v-flex xs12 v-if="isLoading > 0">
      <v-progress-linear indeterminate color="green"></v-progress-linear>
    </v-flex>
    <v-flex xs12 class="pa-1" v-else>
      <v-container>
        <v-form ref="orgForm" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs6 md4>
              <v-checkbox v-model="useSSO" label="Enable SSO" @change="uncheckRequireSSO"></v-checkbox>
            </v-flex>
            <v-flex xs6 md4>
              <v-checkbox v-model="requireSSO" label="Require SSO" :disabled="!useSSO"></v-checkbox>
            </v-flex>
          </v-layout>

          <v-text-field
            v-model="idpIssuer"
            label="IDP Issuer"
            required
            :rules="[rules.required]"
            :disabled="!useSSO"
          ></v-text-field>
          <v-text-field
            v-model="idpSignOnUrl"
            label="IDP Sign On Url"
            required
            :rules="[rules.required]"
            :disabled="!useSSO"
          ></v-text-field>
          <v-textarea
            v-model="idpCertificate"
            label="IDP Certificate"
            required
            :rules="[rules.required]"
            :disabled="!useSSO"
          ></v-textarea>

          <div class="ma-4" />
          <div class="form-btn">
            <v-btn @click="save" color="success" v-if="checkScope(scopeLiterals.SsoUpdate)">Save</v-btn>
          </div>
        </v-form>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';
export default {
  data() {
    return {
      error: null,
      isLoading: 0,
      coupon: '',
      complete: false,
      updateComplete: false,
      orgInfo: {},
      subscribeLoading: false,
      updateLoading: false,
      showUpdate: false,
      valid: false,
      useSSO: false,
      requireSSO: false,
      idpIssuer: '',
      idpSignOnUrl: '',
      idpCertificate: '',
      rules: {
        required: (v) => !!v || 'Field is required',
      },
    };
  },
  methods: {
    uncheckRequireSSO() {
      this.requireSSO = this.useSSO ? this.requireSSO : false;
    },
    async save() {
      let sso = {
        idpSignOnUrl: this.idpSignOnUrl,
        idpCertificate: this.idpCertificate,
        idpIssuer: this.idpIssuer,
      };
      if (this.useSSO) {
        sso = {
          use: this.useSSO,
          requireSso: this.requireSSO,
          ...sso,
        };
      } else {
        sso = {
          use: this.useSSO,
          requireSso: false,
          ...sso,
        };
      }
      await this.doSave(sso);
    },
    async doSave(sso) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        sso,
      };

      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $sso: SSOInput) {
            updateOrg(
              orgId: $orgId
              name: null
              timezone: null
              preferredExchange: null
              baseCurrency: null
              sso: $sso
            ) {
              id
            }
          }
        `,
        variables: vars,
      });
    },
  },
  apollo: {
    org: {
      query: gql`
        query org($orgId: ID!) {
          org(id: $orgId) {
            id
            sso {
              use
              requireSso
              idpSignOnUrl
              idpIssuer
              idpCertificate
            }
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      /** @type {import('apollo-client').FetchPolicy} */
      fetchPolicy: 'network-only',
      update(data) {
        if (data.org && data.org.sso) {
          const sso = data.org.sso;
          this.useSSO = sso.use;
          this.requireSSO = sso.requireSso;
          this.idpIssuer = sso.idpIssuer;
          this.idpCertificate = sso.idpCertificate;
          this.idpSignOnUrl = sso.idpSignOnUrl;
        }
      },
    },
  },
};
</script>

<style>
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripe-card.complete {
  border-color: green;
}
</style>
